import React from "react";
import "./Recruitment.css";

const SubteamCard = ({ data }) => {
    return (
      <div className="item">
        <div className="title">
          <h2>{data.subteam}</h2>
          <hr style={{ 
  borderColor: '#E5B13A', 
  borderWidth: '2px', 
  width: '50%', 
  marginLeft: 'auto', 
  marginRight: '0' 
}} />
        </div>
        <div className="content show">
          <p
            style={{
              borderLeft: "5px solid #E5B13A",
              backgroundColor: "#002245",
              padding: "10px 20px",
              fontStyle: "italic",
              color: "#fff",
              fontSize: window.innerWidth <= 600 ? '1em' : '1.2em',
            }}
          >
            <span
              style={{
                fontSize: window.innerWidth <= 600 ? '1.5em' : '2em',
                position: "absolute",
                left: "10px",
                top: "-10px",
              }}
            >
              "
            </span>
            {data.description}
          </p>
          <h3>Responsibilities</h3>
          <ul>
            {data.responsibilities.map((responsibility, index) => (
              <li key={index}>{responsibility}</li>
            ))}
          </ul>
          <h3>Learning Outcomes</h3>
          <ul>
            {data.learningOutcomes.map((outcome, index) => (
              <li key={index}>{outcome}</li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

// const SubteamCard = ({ data }) => {
//   return (
//     <div className="item">
//       <div className="title">
//         <h2>{data.subteam}</h2>
//         {/* <hr></hr> */}
//       </div>
//       <div className="content show">
//         <p
//           style={{
//             borderLeft: "4px solid #f1f1f1",
//             backgroundColor: "#002245",
//             padding: "10px 20px",
//             fontStyle: "italic",
//             color: "#fff",
//           }}
//         >
//           <span
//             style={{
//               fontSize: "2em",
//               position: "absolute",
//               left: "10px",
//               top: "-10px",
//             }}
//           >
//             "
//           </span>
//           {data.description}
//         </p>
//         <h3>Responsibilities</h3>
//         <ul>
//           {data.responsibilities.map((responsibility, index) => (
//             <li key={index}>{responsibility}</li>
//           ))}
//         </ul>
//         <h3>Learning Outcomes</h3>
//         <ul>
//           {data.learningOutcomes.map((outcome, index) => (
//             <li key={index}>{outcome}</li>
//           ))}
//         </ul>
//       </div>
//     </div>
//   );
// };

export default SubteamCard;
