const mechanicalPositions = [
  {
    subteam: "BTM (Battery Mech)",
    positions: 2,
    description:
      "To design and manufacture battery structures and integrate them with mechanical and electrical systems.",
    responsibilities: [
      "Design of battery structures",
      "Research and testing of different battery cells",
      "Work with composites materials",
      "Electrical-mechanical integration",
      "CFD (Computational Fluid Dynamics) and thermal testing",
      "Battery pack manufacturing",
    ],
    learningOutcomes: [
      "How to design and integrate battery structures with mechanical and electrical systems",
      "Skills in various manufacturing techniques and performing thermal analysis",
    ],
  },
  {
    subteam: "AER (Aerodynamics)",
    positions: 2,
    description:
      "To design and test the aeroshell for the vehicle, focusing on drag reduction and integration with auxiliary components.",
    responsibilities: [
      "Design of the aeroshell for the vehicle",
      "CFD simulations to reduce drag",
      "Model and wind tunnel testing",
      "Placement of solar panels and other auxiliary components",
      "Assisting in the fabrication of the aeroshell",
    ],
    learningOutcomes: [
      "Aerodynamic design principles and drag reduction techniques",
      "Practical experience with CFD simulations and wind tunnel testing",
    ],
  },
  {
    subteam: "STC (Structures)",
    positions: 2,
    description:
      "To design and build the car chassis and integrate all systems, focusing on structural integrity and material testing.",
    responsibilities: [
      "Design and build the car chassis (steel and monocoque)",
      "Material testing with composites",
      "Integrate all other systems of the car",
      "Performing FEA (Finite Element Analysis) simulations",
    ],
    learningOutcomes: [
      "Structural design and analysis techniques",
      "Hands-on experience with material testing and composite manufacturing",
    ],
  },
  {
    subteam: "VDX (Vehicle Dynamics)",
    positions: 2,
    description:
      "To design and optimize vehicle systems such as suspension, steering, and braking, including machining and fabrication of parts.",
    responsibilities: [
      "Design of suspension, steering, braking, and wheel systems",
      "FEA simulations for structural components",
      "Suspension optimization",
      "Machining and fabrication of parts",
    ],
    learningOutcomes: [
      "Vehicle dynamics and the design of key systems like suspension and steering",
      "Simulation and optimization techniques, as well as machining skills",
    ],
  },
];

const electricalPositions = [
  {
    subteam: "PAS (Power and Signals)",
    positions: 2,
    description:
      "To manage and optimize solar array power, motor testing, and PCB design, with a focus on sensor integration and signals management.",
    responsibilities: [
      "Solar Array Power Management and Optimization",
      "Motor Testing and Precision Control",
      "Printed Circuit Board (PCB) Design and Development",
      "Sensor Integration and Signals Management",
    ],
    learningOutcomes: [
      "Electrical Power Management and Motor Control Techniques",
      "PCB Design and Sensor Integration",
    ],
  },
  {
    subteam: "EMB (Embedded Systems)",
    positions: 2,
    description:
      "To integrate and program microcontrollers and development boards, focusing on firmware development and telemetry systems.",
    responsibilities: [
      "Integration and Programming of Microcontrollers and Development Boards",
      "Firmware development",
      "Development of Telemetry Systems and Data Logging",
      "Front-end Development",
    ],
    learningOutcomes: [
      "Embedded Systems Programming and Firmware Development",
      "Skills in Telemetry, Data Logging, and Front-End Development for Embedded Systems",
    ],
  },
  {
    subteam: "STG (Race Strategy)",
    positions: 2,
    description:
      "To develop race strategies using predictive algorithms and optimize performance metrics through data analysis.",
    responsibilities: [
      "Development of Race Strategy using Predictive Algorithms",
      "Integration of Firmware with Strategy Systems",
      "Simulation and Modeling of Race Systems and Scenarios",
      "Data Analysis and Optimization of Race Performance Metrics",
    ],
    learningOutcomes: [
      "Strategy Development based on Simulations and Predictive Models",
      "Firmware Integration and Race Scenario Analysis",
    ],
  },
  {
    subteam: "BMS (Battery Management Systems)",
    positions: 2,
    description:
      "To design and manage power distribution systems for battery management, ensuring safety and reliability.",
    responsibilities: [
      "Design and Management of Power Distribution Systems",
      "Ensuring Safety and Reliability of Battery Cells",
      "PCB Design Specific to Battery Management Systems",
      "Monitoring and Optimization of Battery Performance",
    ],
    learningOutcomes: [
      "Battery Management Techniques Focused on Safety and Efficiency",
      "PCB design with a focus on Power Distribution and Safety Mechanisms",
    ],
  },
];

const businessPositions = [
  {
    subteam: "Logisitics & Finance",
    description: "To plan and manage logistics for events and competitions.",
    responsibilities: [
      "Event & Competition Logistics Planning",
      "Sponsorship Outreach and Management",
      "Reimbursment and Financial Management",
    ],
    learningOutcomes: [
      "Network and leverage sponsorships for Solar",
      "Strengthen Solar’s financial stability",
    ],
  },
  {
    subteam: "Marketing & Graphic Design",
    description:
      "To design marketing materials for various platforms and manage graphic design aspects.",
    responsibilities: [
      "Design and produce digital and print marketing materials",
      "Manage social media platforms",
    ],
    learningOutcomes: ["Design marketing materials for various platforms"],
  },
  {
    subteam: "Frontend Website Developement",
    description:
      "To maintain and develop the front-end of our website using JavaScript, HTML, CSS, and React.",
    responsibilities: [
      "Manage and maintain the front-end of the website",
      "Develop new features and pages",
    ],
    learningOutcomes: ["Maintain website (Frontend)"],
  },
];

module.exports = {
  mechanicalPositions,
  electricalPositions,
  businessPositions,
};
