import React, { useState } from 'react';
import NavigationBar from '../../components/NavigationBar';
import Sidebar from '../../components/Sidebar';
import Footer from '../Footer'
import {
    ProjectMainImageContainer,
    ProjectMainImageContentContainer,
    ProjectMainImageContentText,
    ProjectTimelineContainer,
    ProjectContainer,
    ProjectInfoContainer,
    ProjectImagesContainer,
    ProjectDate,
    ProjectName,
    ProjectSpecsContainer,
    ProjectModel,
    ProjectText,
    ProjectGalleryImage,
    Br,
} from './ProjectPageStyle';

import ProjectPageRaven from '../../assets/ProjectPageImages/ProjectPageRaven.png';
import DaybreakSpecs from '../../assets/ProjectPageImages/DaybreakSpecs.png';
import ProjectPageDaybreak from '../../assets/ProjectPageImages/ProjectPageDaybreak.png';
import RavenSpecs from '../../assets/ProjectPageImages/RavenSpecs.png';

import DaybreakTransparent from '../../assets/ProjectPageImages/DaybreakTransparent.png';
import RavenTransparent from '../../assets/ProjectPageImages/RavenTransparent.png'

import Raven1 from '../../assets/ProjectPageImages/RavenGallery1.JPG'
import Raven2 from '../../assets/ProjectPageImages/RavenGallery2.JPG'
import Raven3 from '../../assets/ProjectPageImages/RavenGallery3.JPG'
import Raven4 from '../../assets/ProjectPageImages/RavenGallery4.JPG'
import Raven5 from '../../assets/ProjectPageImages/RavenGallery5.JPG'
import Daybreak1 from '../../assets/ProjectPageImages/DaybreakGallery1.jpg'
import Daybreak2 from '../../assets/ProjectPageImages/DaybreakGallery2.jpg'
import Daybreak3 from '../../assets/ProjectPageImages/DaybreakGallery3.JPG'
import Daybreak4 from '../../assets/ProjectPageImages/DaybreakGallery4.jpg'
import Daybreak5 from '../../assets/ProjectPageImages/DaybreakGallery5.jpg'

const ProjectPage = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
        <div style={{ "background-color": "#18191f" }}>
            <NavigationBar isOpen={isOpen} toggle={toggle} page={'our-garage'}/>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <div style={{ marginBottom: '5vh' }}>
                <ProjectMainImageContainer>
                        <ProjectMainImageContentText>Projects</ProjectMainImageContentText>
                </ProjectMainImageContainer>
                <ProjectTimelineContainer>
                <ProjectContainer>
                            <ProjectInfoContainer>
                                <ProjectDate>2022-Present</ProjectDate>
                                <ProjectName>BRIGHTSIDE</ProjectName>
                                <h1 style={{ "color": "white" }}><Br/><br/>Coming Soon...<br/><br/><Br/></h1>
                            </ProjectInfoContainer>
                    </ProjectContainer>
                    <ProjectContainer>
                            <ProjectInfoContainer>
                                <ProjectDate>2017-2022</ProjectDate>
                                <ProjectName>DAYBREAK</ProjectName>
                                <ProjectSpecsContainer>
                                    <ProjectText>
                                    NGM SC-M150 in-hub BLDC (3.75kW) 4m&sup2; of Sunpower E60 Solar Cells <Br/><br/>
                                    4130 Chromoly Steel Spaceframe<Br/><br/>
                                    420x Panasonic NCR18650B Li-ion Cells (5.1kWh)<Br/><br/>
                                    Soric Core Twill Weave Carbon Fibre</ProjectText>
                                    <ProjectModel src={DaybreakTransparent}></ProjectModel>
                                    <ProjectText>Daybreak was a huge success in the Formula Sun Grand Prix 2022 competition after years of hard work and dedication. The design focused on being more efficient and competitive. Building on knowledge from Raven and past mistakes, the team made improvements in all aspects of the car, including the battery, aeroshell, and manufacturing process. Despite challenges posed by the pandemic, our bright and motivated team was able to bring Daybreak to competition with outstanding results as our team's first car to officially race.</ProjectText>
                                </ProjectSpecsContainer>
                                <ProjectImagesContainer>
                                    <ProjectGalleryImage src={Daybreak1}></ProjectGalleryImage>
                                    <ProjectGalleryImage src={Daybreak2}></ProjectGalleryImage>
                                    <ProjectGalleryImage src={Daybreak3}></ProjectGalleryImage>
                                    <ProjectGalleryImage src={Daybreak4}></ProjectGalleryImage>
                                    <ProjectGalleryImage src={Daybreak5}></ProjectGalleryImage>
                                </ProjectImagesContainer>
                            </ProjectInfoContainer>
                    </ProjectContainer>
                    <ProjectContainer>
                            <ProjectInfoContainer>
                                <ProjectDate>2007-2017</ProjectDate>
                                <ProjectName>RAVEN</ProjectName>
                                <ProjectSpecsContainer>
                                    <ProjectText>
                                    6m&sup2; of Sunpower C60 Solar Cells<Br/><br/>
                                    4kWh of LiFePO4 Cells Powering a 8kWPGM 132 Brushed DC Motor (8kW)<Br/><br/>
                                    Twill Weave Carbon Fibre/Fibreglass, Soric Core
                                    </ProjectText>
                                    <ProjectModel src={RavenTransparent}></ProjectModel>
                                    <ProjectText>UBC Solar first set out to design a solar powered vehicle to compete in the American Solar Challenge. In 2013, a group of students undertook the design and manufacturing of our first solar car, Raven. Raven was successfully finished to race in the Formula Sun Grand Prix in 2017. Despite best efforts, an electrical issue prevented the team from showcasing Raven at the competition. This did not deter the valuable learning experiences the team gained from Raven, that formed a foundation for the future.</ProjectText>
                                </ProjectSpecsContainer>
                                <ProjectImagesContainer>
                                    <ProjectGalleryImage src={Raven1}></ProjectGalleryImage>
                                    <ProjectGalleryImage src={Raven2}></ProjectGalleryImage>
                                    <ProjectGalleryImage src={Raven3}></ProjectGalleryImage>
                                    <ProjectGalleryImage src={Raven4}></ProjectGalleryImage>
                                    <ProjectGalleryImage src={Raven5}></ProjectGalleryImage>
                                </ProjectImagesContainer>
                            </ProjectInfoContainer>
                    </ProjectContainer>
                </ProjectTimelineContainer>  
            </div>
            <Footer />
        </div>
        </>
    );
};

export default ProjectPage