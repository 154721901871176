import React from 'react';
import { Squash as Hamburger } from 'hamburger-react'
import Logo from '../../assets/NavigationBarImages/Logo.svg';
import {
    Nav,
    NavbarContainer,
    MobileIcon,
    NavNav,
    NavMenu,
    NavItem,
    NavLinks,

} from './NavigationBarStyle';

const Navbar = ({ isOpen, toggle, page }) => {

    return (
        <>
            <Nav>
                <NavLinks to='/' className='logo'>
                    <img width="180vw"
                        src={Logo}>
                    </img>
                </NavLinks>

                <NavbarContainer>
                    <MobileIcon onClick={toggle}>
                        <Hamburger color="#FFFFFF" rounded toggled={isOpen} toggle={toggle} />
                    </MobileIcon>
                    <NavNav>
                        <NavMenu>
                            <NavItem>
                                <NavLinks to='/projects' style={page == 'our-garage' ? { color: '#E5B13A' } : {}}>Projects</NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks to='/competitions' style={page == 'competitions' ? { color: '#E5B13A' } : {}}>Competitions</NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks to='/recruitment' style={page == 'join-us' ? { color: '#E5B13A' } : {}}>Join Us</NavLinks>
                            </NavItem>
                            
                            <NavItem>
                                <NavLinks to='/team-roles' style={page == 'our-team' ? { color: '#E5B13A' } : {}}>Our Team</NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks to='/sponsorships' style={page == 'sponsors' ? { color: '#E5B13A' } : {}}>Sponsors</NavLinks>
                            </NavItem>
                        </NavMenu>
                    </NavNav>


                </NavbarContainer>
            </Nav>
        </>
    );
};

export default Navbar